import store from '@/core/store';
import { VuexModule, getModule, config, Module, Mutation, Action } from 'vuex-module-decorators';

config.rawError = true;

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'Menu'
})
class MenuStore extends VuexModule {
  data = [] as any;
  searchData = [];

  get dataMenu() {
    return this.data;
  }
  @Mutation
  addMenu(menu: any) {
    this.data = menu;
  }
  @Action({ commit: 'addMenu' })
  getMenu() {
    return JSON.parse(localStorage.getItem('menuUser') as string);
  }

  @Mutation
  searchTerm(term: any) {
    const items: any = [];
    term = term.toLowerCase();
    this.data.filter((menuItems: { title: string; type: string; children: any[]; icon: any }) => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter((suSubItems: { title: string; icon: any }) => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
      this.searchData = items;
    });
  }
  @Mutation
  setNavActive(item: any) {
    if (!item.active) {
      this.data.forEach((a: { active: boolean; children: any[] }) => {
        if (this.data.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }
  @Mutation
  setActiveRoute(item: any) {
    this.data.filter((menuItem: { active: boolean; children: any[] }) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }
  @Action({ commit: 'searchTerm' })
  searchTermAct(term: any) {
    return term;
  }
  @Action({ commit: 'setNavActive' })
  setNavActiveAct(item: any) {
    return item;
  }
  @Action({ commit: 'setActiveRoute' })
  setActiveRouteAct(item: any) {
    return item;
  }
}
export default getModule(MenuStore);
