var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('aside',{staticClass:"sidebarMenu",on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_c('div',{staticClass:"main-header-left d-none d-lg-block"},[_c('div',{staticClass:"logo-wrapper justify-content-around"},[_c('router-link',{attrs:{"to":{ name: 'DashboardDefault' }}},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"50px","object-fit":"contain"},attrs:{"src":_vm.urlLogoCompany,"alt":"Logo Company"}})])],1)]),_c('div',{staticClass:"sidebar custom-scrollbar"},[_c('div',{staticClass:"sidebar-user text-center"},[_c('div',[_c('b-icon',{staticClass:"rounded-circle bg-secondary p-2",attrs:{"icon":"person-fill","font-scale":"4"}})],1),_c('h6',{staticClass:"mt-3 f-14",domProps:{"textContent":_vm._s(_vm.userName)}})]),_c('ul',{staticClass:"sidebar-menu d-flex justify-content-center flex-column",style:([
        _vm.layout.settings.sidebar.type == 'horizontal_sidebar'
          ? _vm.layout.settings.layoutType == 'rtl'
            ? { 'margin-right': _vm.margin + 'px' }
            : { 'margin-left': _vm.margin + 'px' }
          : { margin: '0px' }
      ]),attrs:{"id":"myDIV"}},[_c('li',{staticClass:"left-arrow",class:{
          'd-none': _vm.layout.settings.layoutType == 'rtl' ? _vm.hideLeftArrowRTL : _vm.hideLeftArrow
        },on:{"click":function($event){_vm.layout.settings.sidebar.type === 'horizontal_sidebar' && _vm.layout.settings.layoutType === 'rtl'
            ? _vm.scrollToLeftRTL()
            : _vm.scrollToLeft()}}},[_c('i',{staticClass:"fa fa-angle-left"})]),_vm._l((_vm.menuItems),function(menuItem,indexMenuItem){return _c('li',{key:indexMenuItem,class:{ active: menuItem.active }},[(menuItem.type == 'sub')?_c('a',{staticClass:"sidebar-header d-flex justify-content-between align-items-center",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setNavActive(menuItem)}}},[_c('div',{staticClass:"d-flex align-items-center"},[(menuItem.icon)?_c('iconify-icon',{staticClass:"mr-1",class:_vm.isHovered ? 'menuVisibleIcon' : 'menuHiddenIcon',attrs:{"icon":menuItem.icon}}):_vm._e(),_c('span',{staticClass:"menuTitle",class:_vm.isHovered ? 'menuVisible' : 'menuHidden'},[_vm._v(" "+_vm._s(_vm.$t('menu.' + menuItem.title))+" "),(menuItem.badgeType)?_c('span',{class:[_vm.isHovered ? 'menuVisible' : 'menuHidden', 'badge badge-pill badge-' + menuItem.badgeType]},[_vm._v(_vm._s(menuItem.badgeValue))]):_vm._e()])],1),(menuItem.children)?_c('i',{staticClass:"fa fa-angle-right pull-right",class:_vm.isHovered ? 'menuVisible' : 'menuHidden'}):_vm._e()]):_vm._e(),(menuItem.type == 'link')?_c('router-link',{staticClass:"sidebar-header",attrs:{"to":menuItem.path,"router-link-exact-active":""}},[(menuItem.icon)?_c('feather',{staticClass:"top",attrs:{"type":menuItem.icon}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$t('menu.' + menuItem.title))+" "),(menuItem.badgeType)?_c('span',{class:'badge badge-' + menuItem.badgeType + ' ml-3'},[_vm._v(_vm._s(menuItem.badgeValue))]):_vm._e()]),(menuItem.children)?_c('i',{staticClass:"fa fa-angle-right pull-right"}):_vm._e()],1):_vm._e(),(menuItem.children && _vm.isHovered)?_c('recursive-menu',{attrs:{"menu":menuItem.children}}):_vm._e()],1)}),_c('li',{staticClass:"right-arrow",class:{
          'd-none': _vm.layout.settings.layoutType === 'rtl' ? _vm.hideRightArrowRTL : _vm.hideRightArrow
        },on:{"click":function($event){_vm.layout.settings.sidebar.type === 'horizontal_sidebar' && _vm.layout.settings.layoutType === 'rtl'
            ? _vm.scrollToRightRTL()
            : _vm.scrollToRight()}}},[_c('i',{staticClass:"fa fa-angle-right"})])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }