
import Vue from 'vue';
import Component from 'vue-class-component';
import LayoutStore from '@/core/store/modules/Layout';
import MenuStore from '@/core/store/modules/Menu';
import { Watch } from 'vue-property-decorator';
import Customizer from '@/general/infrastructure/shared/Customizer.vue';
import Header from '@/general/infrastructure/shared/Header.vue';
import Sidebar from '@/general/infrastructure/shared/Sidebar.vue';
import RightSidebar from '@/general/infrastructure/shared/RightSidebar.vue';

@Component({
  name: 'Main',
  components: {
    Customizer,
    Header,
    Sidebar,
    RightSidebar
  }
})
export default class Main extends Vue {
  rightsidebarToggleVar = false;
  mobileheaderToggleVar = false;
  sidebarToggleVarData = false;
  resized = false;
  width = 0;
  height = 0;

  //Hook
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.resized = this.sidebarToggleVarData;
    this.$store.dispatch('Layout/setAct');
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.menuItems.filter((items: any) => {
      if (items.path === this.$route.path) MenuStore.setActiveRouteAct(items);
      if (!items.children) return false;
      items.children.filter((subItems: any) => {
        if (subItems.path === this.$route.path) MenuStore.setActiveRouteAct(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems: { path: string }) => {
          if (subSubItems.path === this.$route.path) MenuStore.setActiveRouteAct(subSubItems);
        });
      });
    });
  }
  @Watch('')
  sidebarToggleVar() {
    this.resized = this.width <= 991 ? !this.sidebarToggleVarData : this.sidebarToggleVarData;
  }

  //Property Computed
  get layout() {
    return LayoutStore.layout;
  }
  get menuItems() {
    return MenuStore.data;
  }

  //Methods
  sidebarToggle(value: boolean) {
    this.sidebarToggleVarData = !value;
  }
  rightsidebarToggle(value: boolean) {
    this.rightsidebarToggleVar = value;
  }
  mobiletoggleToggle(value: boolean) {
    this.mobileheaderToggleVar = value;
  }
  handleResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }
}
