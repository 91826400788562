
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapGetters } from 'vuex';
import LanguageSwitcher from '@/general/infrastructure/LanguageSwitcher.vue';
import MenuStore from '@/core/store/modules/Menu';
import { Watch } from 'vue-property-decorator';
import { destroyToken } from '@/core/plugins/FunctionUtil';
import { toogleFullScreen } from '@/core/plugins/FullScreen';
import Customizer from '@/general/infrastructure/shared/Customizer.vue';

const body = document.getElementsByTagName('body')[0];
@Component({
  name: 'Header',
  components: {
    Customizer,
    LanguageSwitcher
  },
  computed: {
    ...mapGetters('Login', ['loggedIn'])
  },
  methods: {
    ...mapActions('Login', ['destroyToken'])
  }
})
export default class Header extends Vue {
  //Data
  username = localStorage.getItem('userNameCompany') || '';
  terms = '';
  searchResult = false;
  searchResultEmpty = false;
  toggleSidebarVar = false;
  clicked = false;
  mobile_toggle = false;
  mobile_search = false;
  @Watch('menuItems')
  menuItemsWatch() {
    this.terms ? this.addFix() : this.removeFix();
    if (!this.menuItems.length) {
      this.searchResultEmpty = true;
    } else {
      this.searchResultEmpty = false;
    }
  }
  //Property Computed
  get menuItems() {
    return MenuStore.searchData;
  }
  get logo() {
    return process.env.VUE_APP_URL_TOKEN + `/${localStorage.getItem('logoUser')}`;
  }
  get logoCompany() {
    return localStorage.getItem('urlLogoCompany');
  }
  //Methods
  searchTerm(): void {
    this.$store.dispatch('Menu/searchTermAct', this.terms);
  }
  logout(): void {
    destroyToken();
  }
  addFix(): void {
    body.classList.add('offcanvas');
    this.searchResult = true;
  }
  removeFix(): void {
    body.classList.remove('offcanvas');
    this.searchResult = false;
    this.terms = '';
  }
  toggleSidebar(): void {
    this.toggleSidebarVar = !this.toggleSidebarVar;
    this.clicked = !this.toggleSidebarVar;
    this.$emit('clicked', this.clicked);
  }
  toggleFullscreen(): void {
    toogleFullScreen();
  }
}
