
import Vue from 'vue';
import Component from 'vue-class-component';
import { localize } from 'vee-validate';
import { selectedLocale } from '@/core/plugins/i18n';

@Component({
  name: 'LanguageSwitcher'
})
export default class LanguageSwitcher extends Vue {
  //Data
  isVisible = false;
  focusedIndex = 0;
  localeLang = '';

  //Hook
  mounted() {
    if (selectedLocale === 'en') {
      this.localeLang = 'us';
    }
  }

  //Methods
  hideDropdown() {
    this.isVisible = false;
    this.focusedIndex = 0;
  }
  setLocale(locale: string) {
    this.$i18n.locale = locale;
    if (locale === 'en') {
      this.localeLang = 'us';
    } else {
      this.localeLang = locale;
    }
    import(`vee-validate/dist/locale/${locale}`).then(locale => {
      localize(selectedLocale, locale);
    });
    this.hideDropdown();
  }
}
